import React from 'react'
import "./Footer.css"
import footer_logo from "../Assets/Pink Watercolour Custom Gifts Etsy Shop Icon.png"
import instagram_icon from "../Assets/instagram.png"
import facebook_icon from "../Assets/facebook.png"
import whatsapp_icon from "../Assets/whatsapp.png"
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <div className='footer'>
      <div className="footer-logo">
        <img src={footer_logo} alt=''></img>
      </div>
      <ul className='footer-links'>
      <Link className= "footer-navigation-item" to="/terms-conditions"> <li>Terms and Conditions</li></Link>
       <Link className= "footer-navigation-item" to="/privacy-policy"><li>Privacy Policy</li></Link> 
       <Link className='footer-navigation-item' to="/refund-return-policy"> <li>Refund & Return Policy</li></Link>
        <Link className='footer-navigation-item' to="/shipping-delivery-policy" ><li>Shipping & Delivery Policy</li></Link>
        <Link className='footer-navigation-item' to="/contactUs" ><li>Contact US</li></Link>
      </ul>
      <div className="footer-social-icon">
        <div className="footer-icons-container">
            <img src={instagram_icon} alt=''></img>
        </div>
        <div className="footer-icons-container">
            <img src={facebook_icon} alt=''></img>
        </div>
        <div className="footer-icons-container">
            <img src={whatsapp_icon} alt=''></img>
        </div>
      </div>
      <div className="footer-copyright">
        <hr/>
        <p>Copyright @ 2024 - All Right Reserved</p>
      </div>
    </div>
  )
}

export default Footer

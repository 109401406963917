import React from 'react'
import "./Hero.css"
import hand_icon from "../Assets/wave.png"
import arrow from '../Assets/right-arrow.png'
import hero_img from "../Assets/8630656.png"
import {Link} from "react-router-dom"
const Hero = () => {
  return (
    <div className='hero'>
        <div className="hero-left">
            <h2>NEW ARRIVALS</h2>
            <div>
                <div className="hero-hand-icon">
                    <p>new</p>
                    <img src={hand_icon} alt='not found'></img>
                </div>
                <p>collections</p>
                <p>for everyone</p>
            </div>
           <Link className='link-tag' to={"/silverJewellery"}> <div className="hero-latest-btn"  >
                <div>Latest Collection</div>
                <img src={arrow} alt=''></img>
            </div>
            </Link>
        </div>
        <div className="hero-right">
            <img src={hero_img} alt=''></img>
        </div>
      
    </div>
  )
}

export default Hero

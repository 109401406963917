import React, { createContext, useEffect, useState } from "react";
// import all_product_data from "../Components/Assets/all_products";

export const ShopContext = createContext(null);

const getDefaultCart = ()=>{
    let cart ={};

    for (let index = 0; index < 300 +1; index++) {
        cart[index] = 0;      
    }

    return cart;
}
const ShopContextProvider  = (props) => {
    const [all_product_data,setAll_Product_Data] = useState([]);
    const [cartItem,setCartItem] = useState(getDefaultCart);

    useEffect(()=>{
        fetch('https://bffstorebackend.onrender.com/allproducts')
        .then((res)=> res.json())
        .then((data)=> setAll_Product_Data(data))
        
        if(localStorage.getItem('auth-token')){
            fetch('https://bffstorebackend.onrender.com/getcart',{
                method:'POST',
                headers:{
                    Accept:'application/form-data',
                    'auth-token':`${localStorage.getItem('auth-token')}`,
                    'Content-type':'application/json',
                },
                body:"",
            }).then((res)=>res.json()).then((data)=>setCartItem(data));
        }
    },[])

    // console.log(all_product_data);
    
    // console.log(cartItem);
   const addToCart = (itemId) =>{
    setCartItem((prev)=>({
        ...prev,[itemId]:prev[itemId]+1})
    );
    if(localStorage.getItem('auth-token')){
        fetch('https://bffstorebackend.onrender.com/addtocart',{
            method:'POST',
            headers:{
                Accept:'application/form-data',
                'auth-token':`${localStorage.getItem('auth-token')}`,
                'Content-type':'application/json'
            },
            body:JSON.stringify({"itemId" : itemId})
        })
        .then((res)=>res.json())
        .then((data)=>console.log(data));
    }
    
   }
   const removeFromCart = (itemId) =>{
    setCartItem((prev)=>({
        ...prev,[itemId]:prev[itemId]-1})
    )
    if(localStorage.getItem('auth-token')){
        fetch('https://bffstorebackend.onrender.com/removefromcart',{
            method:'POST',
            headers:{
                Accept:'application/form-data',
                'auth-token':`${localStorage.getItem('auth-token')}`,
                'Content-type':'application/json'
            },
            body:JSON.stringify({"itemId" : itemId})
        })
        .then((res)=>res.json())
        .then((data)=>console.log(data));
    }
   }

   const getTotalCartAmount = ()=>{
    let totalAmount = 0;
    for(const item in cartItem){
        if(cartItem[item]>0){
            let itemInfo = all_product_data.find((product)=>(product.id === Number(item)));

            if (itemInfo) {
                // Add the price of the item multiplied by its quantity to totalAmount
                totalAmount += itemInfo.new_price * cartItem[item];
            }
        }
    }
    return totalAmount;
   }


   const getTotalCartItems = ()=>{
    let totalItem = 0;
    for(const item in cartItem){
        if(cartItem[item]>0){
            totalItem += cartItem[item];
        }
    }
    return totalItem;
   }

   const conTextValue = {all_product_data,cartItem,addToCart,removeFromCart,getTotalCartAmount,getTotalCartItems};
    return(
        <ShopContext.Provider value={conTextValue}>
            {props.children}
        </ShopContext.Provider>
    )
}

export default ShopContextProvider;
// import product images
import p1_image from "./indian-festival-raksha-bandhan-background-free-photo.jpg"
// add same data to the both dataset in accending order
// make a object of data_product for use in main page
let data_product = [
    {
        id:1,
        name:"Not Found ! 1 at this time",
        // category: "imitation jewellery",
        image:p1_image,
        new_price:50.00,
        old_price:80.50,

    },
    {
        id:2,
        name:"Not Found ! 2 at this time",
        // category: "silver jewellery",
        image:p1_image,
        new_price:50.00,
        old_price:80.50,

    },
    {
        id:3,
        name:"Not Found ! 3 at this time",
        // category: "rakhi",
        image:p1_image,
        new_price:50.00,
        old_price:80.50,

    },
    {
        id:4,
        name:"Not Found ! 4 at this time",
        // category: "imitation jewellery",
        image:p1_image,
        new_price:50.00,
        old_price:100.50,

    }
]
export default data_product;
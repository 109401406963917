import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import LoginSignup from './Pages/LoginSignup';
import Footer from './Components/Footer/Footer';
//imports main banners for jewellery and rakhi total 3
import imitationBanner from "./Components/Assets/imitationjewellery.jpg";
import silverBanner from "./Components/Assets/silverjewellery.jpg";
import rakhiBanner from "./Components/Assets/raksha-bandhan-sale-banner_1017-19911.jpg";
import OrderConfirmed from './Pages/OrderConfirmed';
import TermsConditions from './Pages/TermsConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ReturnPolicy from './Pages/ReturnPolicy';
import ShippingDelivery from './Pages/ShippingDelivery';
import ContactUs from './Pages/ContactUs';



function App() {
  return (
    <div>
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path='/' element = {<Shop/>}></Route>
        <Route path='/imitationJewellery' element = {<ShopCategory banner ={imitationBanner} category = "Imitation Jewellery"/>}></Route>
        <Route path='/silverJewellery' element = {<ShopCategory banner={silverBanner} category = "Silver Jewellery"/>}></Route>
        <Route path='/rakhi' element = {<ShopCategory banner={rakhiBanner} category = "Rakhi"/>}></Route>
        <Route path='/product' element ={<Product/>}>
          <Route path=':productId' element={<Product/>}/>
        </Route>
        <Route path='/cart' element = {<Cart/>}></Route>
        <Route path='/login' element = {<LoginSignup/>}></Route>
        <Route path='/cart/confirmedOrder' element= {<OrderConfirmed/>} />
        <Route path='/terms-conditions' element={<TermsConditions/>}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
        <Route path='/refund-return-policy' element={<ReturnPolicy/>}/>
        <Route path='/shipping-delivery-policy' element={<ShippingDelivery/>}/>
        <Route path='/contactUs' element={<ContactUs/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

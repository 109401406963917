// TermsConditions.jsx
import React from 'react';
import "./CSS/TermsConditions.css";

const TermsConditions = () => {
    return (
        <div className="terms-container">
            <div className='terms-container-inner'>

            
            <p className='terms-conditions-paragraph'>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
            
            <div>

            <p className='es-of-terms'>Defin itions:</p>
            <p className='text-terms-conditions heading-of-term-condition'>For the purposes of this Disclaimer:</p> <br />
            
            <p className='text-terms-conditions'>“Company” (referred to as either “the Company”, “We”, “Us” or “Our” in this Disclaimer) refers to BFFstoreIN</p>
            <p className='text-terms-conditions'>“Goods” refers to the items offered for sale on the Service.
            </p>
            <p className='text-terms-conditions'>“Orders” means a request by You to purchase Goods from Us.</p>
            <p className='text-terms-conditions'>
“Service” refers to the Website.
</p>
            <p className='text-terms-conditions'>“Website” refers to www.bffstorein.com

            </p>
            <p className='text-terms-conditions'>“You” means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            </p> 


            </div><br />
            <div className='therms-conditions-box'>
                <p className='text-terms-conditions'>By ordering any of our products, you agree to be come under by these terms & conditions.
                </p>
                <br />
                    <li className='list-policies'>1. Shipping costs and payment fees are recognized before confirming the purchase.
                    </li>
                    <li className='list-policies'>2. www.bffstorein.com reserves the right to modify any information without prior notice
                    </li>
                    <li className='list-policies'>3. Please note that local charges may occur.
                    </li>
                    <li className='list-policies'>4. All personal information you provide us with or that we obtain will be handled by www.bffstorein.com as responsible for the personal information.
                    </li>
                    <li className='list-policies'>5. Card information is transmitted over secure SSL encryption and is not stored.
                    </li>
                    <li className='list-policies'>6. The price applicable is that set at the date on which you place your order.
                    </li>
                    <li className='list-policies'>7. Events outside www.sapoly.com control shall be considered force majeure.
                    </li>
            
            </div>
            <div>
            <p className='es-of-terms'>Personal Information </p>

            <p className='general-paragraph-condition'>All personal information you provide us with or that we obtain will be handled by www.bffstorein.com as responsible for the personal information. The personal information you provide will be used to ensure deliveries to you, the credit assessment, to provide offers and information on our catalogue to you. The information you provide is only available to www.bffstorein.comand will not be shared with other third parties. You have the right to inspect the information held about you. You always have the right to request www.bffstorein.com to alter the information held about you. By accepting the www.bffstorein.com Conditions, you agree to the above.</p>
            </div>

            <div>
            <p className='es-of-terms'>Force Majeure</p>

            <p className='general-paragraph-condition'>Events outside www.bffstorein.comcontrol, which is not reasonably foreseeable, shall be considered force majeure, meaning that www.bffstorein.com is released from www.bffstorein.com obligations to fulfil contractual agreements. Example of such events are government action or omission, new or amended legislation, conflict, embargo, fire or flood, sabotage, accident, war, natural disasters, strikes or lack of delivery from suppliers. The force majeure also includes government decisions that affect the market negatively and products, for example, restrictions, warnings, ban, etc.
            </p>
            </div>

            <div>
            <p className='es-of-terms'>Payment</p>

            <p className='general-paragraph-condition'>All products remain www.bffstorein.com property until full payment is made. The price applicable is that set at the date on which you place your order. Shipping costs and payment fees are recognized before confirming the purchase.
            </p> <br />
            <p className='general-paragraph-condition'>All transfers conducted through www.bffstorein.com are handled and transacted through third party dedicated gateways to guarantee your protection. Card information is not stored and all card information is handled over SSL encryption. Please read the terms & conditions for the payment gateway chosen for the transaction as they are responsible for the transactions made.
            </p>
            </div>
            <div>
            <p className='es-of-terms'>Local Taxes
            </p>

            <p className='general-paragraph-condition'>Please note that local charges (sales tax, customs duty) may occur, depending on your region and local customs duties. These charges are at the customer’s own expense.
            </p> 
            
            </div>
            <div>
            <p className='es-of-terms'>Cookies
            </p>

            <p className='general-paragraph-condition'>www.bffstorein.com uses cookies. A cookie is a small text file stored on your computer that contains information that helps the website to identify and track the visitor. Cookies do no harm to your computer, consist only of text, cannot contain viruses and occupies virtually no space on your hard drive. There are two types of cookies: \”Session Cookies\” and cookies that are saved permanently on your computer.
            </p><br />

            <p className='general-paragraph-condition'>The first type of cookie commonly used is \”Session Cookies\”. During the time you visit the website, our web server assigns your browser a unique identifier string so as not to confuse you with other visitors. A \”Session Cookie\” is never stored permanently on your computer and disappears when you close your browser. To use www.sapoly.com without troubles you need to have cookies enabled.
            </p><br /> 

            <p className='general-paragraph-condition'>The second type of cookie saves a file permanently on your computer. This type of cookie is used to track how visitors move around on the website. This is only used to offer visitors better services and support. The text files can be deleted. On www.bffstorein.com we use this type of cookie to keep track of your shopping cart and to keep statistics of our visitors. The information stored on your computer is only a unique number, without any connection to personal information.
            </p><br /> 
            
            </div>
            
            
            <div>
            <p className='es-of-terms'>Additional Information
            </p>

            <p className='general-paragraph-condition'>www.bffstorein.com reserves the right to amend any information, including but not limited to prices, technical specifications, terms of purchase and product offerings without prior notice. At the event of when a product is sold out, www.bffstorein.com has the right to cancel the order and refund any amount paid in the best way. www.bffstorein.com shall also notify the customer of equivalent replacement products if available.
            </p><br />
            
            </div>
            
            </div>
            
        </div>
    );
};

export default TermsConditions;

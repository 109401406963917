import React from 'react'

const ReturnPolicy = () => {
  return (
    <div className="terms-container">
        <div className='terms-container-inner'>
            <p className='privacy-text'>
            Our refund policy states that all the sales on the part of Shrianand Poly don’t offer any refund. We urge our customers to review their choices before making any purchase. Please feel free to reach out to our support team for further questions.
            </p>
            <p className='privacy-text'>
            Customer is requested not to accept any damaged product from the logistics partner. If customer claims any disputes later e.g. damaged material/short quantity, then he needs to share an unboxing video to claim the same. Otherwise, the claim would not be considered.
            </p>

        </div>
      
    </div>
  )
}

export default ReturnPolicy

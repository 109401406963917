import React, { useContext, useState } from 'react'
import "./Navbar.css"
import logo from "../Assets/Pink Watercolour Custom Gifts Etsy Shop Icon.png"
import cart_icon from "../Assets/cart-shopping-solid.svg"
import { Link } from 'react-router-dom';
import menu_btn from "../Assets/menu_24dp_FILL0_wght400_GRAD0_opsz24.svg"
import { ShopContext } from '../../Context/ShopContext';

export default function Navbar() {
    const [menu,setMenu] = useState("home");
    const [sidebarOpen,setSidebarOpen] = useState(false);
    const {getTotalCartItems} = useContext(ShopContext);

  return (
    <div className='navbar'>
        <div className='nav-logo' >
            <img src={logo} alt="" srcSet="" />
        </div>
        <ul className='nav-menu' id={sidebarOpen ? "nav-menu-open" : ""}>
            <li onClick={()=>{setMenu("home")}}><Link className="footer-navigation-item" to='/'>Home</Link>{menu === "home" ? <hr/> :<></>}</li>
            <li onClick={()=>{setMenu("Imitation Jewellery")}}><Link className="footer-navigation-item" to ='/imitationJewellery'>Imitation Jewellery</Link>{menu === "Imitation Jewellery" ? <hr/> :<></>}</li>
            <li onClick={()=>{setMenu("Silver Jewellery")}}><Link className="footer-navigation-item" to='/silverJewellery'>Silver Jewellery</Link>{menu === "Silver Jewellery" ? <hr/> :<></>}</li>
            <li onClick={()=>{setMenu("Rakhi")}}><Link className="footer-navigation-item" to='/rakhi' >Rakhi</Link>{menu === "Rakhi" ? <hr/> :<></>}</li>
        </ul>
      
       
        <div className='nav-login-cart'>
            {localStorage.getItem('auth-token')
            ?<button onClick={()=>{localStorage.removeItem('auth-token');window.location.replace('/');}}>Logout</button>: <Link to='/login'><button>Login</button></Link>}
            
            <Link to='/cart'><img src={cart_icon} alt='' ></img></Link>
            <div className='nav-cart-count'>{getTotalCartItems()}</div>
        </div>
        <img className="menu"src={menu_btn} onClick={()=>{
            setSidebarOpen(!sidebarOpen);
        }}
         alt="" />
    </div>
  )
}

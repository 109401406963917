import React from 'react'

const ContactUs = () => {
  return (
    <div className="terms-container">
        <div className='terms-container-inner'>

            <p className='privacy-text'> <b>
                E-mail :
            </b>
           <a className= "footer-navigation-item" href='mailto:bfftkub@gmail.com'>bfftkub@gmail.com</a>
            </p>
            <p className='privacy-text' > <b>
            Phone :
            </b>
              <a className= "footer-navigation-item" href="tel:+918849770909">+91 8849770909</a>  
            </p>
            <p className='privacy-text'>
            (Working Hours: Mon to Sat at 10:00 AM to 6:00 PM)
            </p>
            <p className='privacy-text' > <b>
            Address :
            </b>
              <a className= "footer-navigation-item" href="https://maps.app.goo.gl/MjW3NH2Z5dZgiaLN8">B103, Maruti Trade Center, Velanja, Surat, Gujarat, 394150</a>  
            </p>
            </div>

    </div>
  )
}

export default ContactUs

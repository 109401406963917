import React, { useState } from 'react'
import "./CSS/LoginSignup.css";

const LoginSignup = () => {

  const [state,setState] = useState("Login");
  // show otp field
  const [showOtp , setShowOtp] = useState(false);
  
  const[formData,setFormData] = useState({
    username:"",
    password:"",
    number:"",
  })

  const changeHandler = (e) =>{
    setFormData({...formData,[e.target.name]:e.target.value})
  }

  const login = async()=>{
    console.log("Login function executed.",formData);
    let responseData;
    await fetch('https://bffstorebackend.onrender.com/login',{
      method:'POST',
      headers:{
        Accept:'application/formData',
        'Content-type':'application/json',
      },
      body:JSON.stringify(formData),
    }).then((res)=>res.json()).then((data)=>responseData = data);

    if(responseData.success){
      localStorage.setItem('auth-token',responseData.token);
      window.location.replace("/");
    }else{
      alert(responseData.errors)
    }
  }
  const signup = async()=>{
    console.log("Sign Up function executed.",formData)
    let responseData;
    await fetch('https://bffstorebackend.onrender.com/signup',{
      method:'POST',
      headers:{
        Accept:'application/formData',
        'Content-type':'application/json',
      },
      body:JSON.stringify(formData),
    }).then((res)=>res.json()).then((data)=>responseData = data);

    if(responseData.success){
      localStorage.setItem('auth-token',responseData.token);
      window.location.replace("/");
    }else{
      alert(responseData.errors)
    }
  }

  // handle phone submit button
  const handlePhoneSubmit = (event)=>{
    event.preventDefault();

    // phone validations
    const regex = /[^0-9]/g;
    if(formData.number.length < 10 || regex.test(formData.number)){
      alert("Invalid Phone Number");
      return;
    }

    // Call BE API 

    // Show otp field
    setShowOtp(true);
}

  return (
    <div className='loginsignup'>
      <form onSubmit={handlePhoneSubmit} className="loginsignup-container">
        <h1>{state}</h1>
        <div className="loginsignup-fields">
          {state === "Sign Up"?<input type='text' name='username' value={formData.username}  onChange={changeHandler}placeholder='Your Name'/>:<></>}
          <input name='number' value={formData.number} onChange={changeHandler} type="text" placeholder='Phone Number' />
          <input name='password' value={formData.password} onChange={changeHandler} type="password" placeholder='Password'/>
        </div>
        <button onClick={()=>{state === "Login"?login():signup()}}>Continue</button>
        {state === "Sign Up"?<p className="loginsignup-login">Already have an account? <span onClick={()=>{
          setState("Login");
        }}>Login here</span></p>:<p className="loginsignup-login">Create an account? <span onClick={()=>{
          setState("Sign Up");
        }}>Click here</span></p>}
        
        
        <div className="loginsignup-agree">
          <input type="checkbox" name='' id='' />
          <p>By continuing, i agree to the terms of use & privacy policy.</p>
        </div>
      </form>
  
    </div>
  )
}

export default LoginSignup



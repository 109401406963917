import React, { useContext, useState, useEffect } from "react";
import "./CartItems.css";
import { ShopContext } from "../../Context/ShopContext";
import axios from "axios";
import logo from "../Assets/Pink Watercolour Custom Gifts Etsy Shop Icon.png";
import remove_icon from "../Assets/cart-cross-icon.png";

const CartItems = () => {
  const { all_product_data, cartItem, removeFromCart, getTotalCartAmount } =
    useContext(ShopContext);

  // State for form fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [Area, setArea] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  // Payment-related states
  const [paymentId, setPaymentId] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [purchasedProduct, setPurchasedProduct] = useState([]);
  const [orderType, setOrderType] = useState("online");

  // Function to load external Razorpay script
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  // Function to create Razorpay order
  const createRazorpayOrder = (amount) => {
    let data = JSON.stringify({
      amount: amount * 100, // Amount in paisa
      currency: "INR",
    });

    let config = {
      method: "post",
      url: "https://bffstorebackend.onrender.com/orders",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Razorpay order created:", response.data);
        handleRazorpayScreen(response.data.amount);
      })
      .catch((error) => {
        console.error("Error creating Razorpay order:", error);
      });
  };

  

  // Function to handle Razorpay payment screen
  const handleRazorpayScreen = async (amount) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Please check your internet connection.");
      return;
    }

    const options = {
      key: "rzp_live_UjU8CjpoVefD0O", // Replace with your razorpay key
      amount: amount,
      currency: "INR",
      name: "BFFstoreIn",
      description: "Payment to BFFstoreIN",
      image: logo,
      handler: async function (response) {
        try{

        setPaymentId(response.razorpay_payment_id);
        setPurchaseComplete(true);

        // Find the purchased products based on cartItem
        const purchasedProducts = Object.keys(cartItem).map((productId) => {
          const quantity = cartItem[productId];
          const product = all_product_data.find(
            (p) => p.id === parseInt(productId)
          );
          console.log("purchased product" + product);
          if (product) {
            return {
              productId: product.id,
              name: product.name,
              price: product.new_price,
              quantity: quantity,
            };
          } else {
            console.error(
              `Product with ID ${productId} not found in all_product_data`
            );
            return null; // or handle this case as per your application logic
          }
        }).filter((product) => product !== null);

        // Filter out any null values if necessary
        const validPurchasedProducts = purchasedProducts.filter((product) => product !== null);
        // setPurchasedProduct(validPurchasedProducts);
      

        // Update state with purchased products
        setPurchasedProduct(validPurchasedProducts);

        // Save all purchased products to the backend
        savePurchaseDetails({
          name,
          email,
          contact,
          houseNo,
          Area,
          pincode,
          city,
          state,
          products: validPurchasedProducts,
          amount: getTotalCartAmount(),
          orderType,
          paymentId: response.razorpay_payment_id,
        });

        // Fetch payment status after saving purchase
      await fetchPaymentStatus(response.razorpay_payment_id);

      }catch(error){
        console.error("Payment handling error:", error);
      }
      },
      prefill: {
        name: name,
        email: email,
        contact: contact,
      },
      theme: {
        color: "#060640",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  // Function to save purchase details to backend
  const savePurchaseDetails = (purchaseData) => {
    // console.log("Purchase Data:", purchaseData); // Log the purchase data
    axios
      .post("https://bffstorebackend.onrender.com/purchase", purchaseData)
      .then((response) => {
        console.log("Purchase saved successfully:", response.data);

        localStorage.setItem('order' , purchaseData);
        // Optionally set some state or perform actions after saving
      })
      .catch((error) => {
        console.error("Error saving purchase:", error);
      });
  };

  // Function to fetch payment status
  const fetchPaymentStatus = (paymentId) => {
    axios
      .get(`https://bffstorebackend.onrender.com/payment/${paymentId}`)
      .then((response) => {
        console.log("Payment status fetched:", response.data);
        setPaymentStatus(response.data);
      })
      .catch((error) => {
        console.error("Error fetching payment status:", error);
      });
  };

  // Function to handle form submission for user details and payment initiation
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isLogin = localStorage.getItem('auth-token');
    
    if(!isLogin){
      return window.location.replace('/login');
    }
    
    const purchasedProducts = Object.keys(cartItem)
      .map((productId) => {
        const quantity = cartItem[productId];
        const product = all_product_data.find(
          (p) => p.id === parseInt(productId)
        );

        if (product) {
          return {
            productId: product.id,
            name: product.name,
            price: product.new_price,
            quantity: quantity,
          };
        } else {
          // console.error(
          //   `Product with ID ${productId} not found in all_product_data`
          // );
          return null;
        }
      })
      .filter((product) => product !== null);

    if (orderType === "online") {
      await createRazorpayOrder(getTotalCartAmount());

    } else if (orderType === "offline") {
      // Save offline purchase details directly to the backend
      savePurchaseDetails({
        name,
        email,
        contact,
        houseNo,
        Area,
        pincode,
        city,
        state,
        products: purchasedProducts,
        amount: getTotalCartAmount(),
        paymentId: "COD", // Offline order, no Razorpay payment ID
        orderType: "offline", // Specify the order type
      });

      // Set the purchase as complete for offline orders
      setPurchaseComplete(true);
      setPurchasedProduct(purchasedProducts);
    }
  };

  // // Function to load external script
  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };

  useEffect(() => {
    // Load initial purchased products if purchaseComplete is true (e.g., after returning from payment success)
    if (purchaseComplete) {
      const purchasedProducts = Object.keys(cartItem).map((productId) => {
        const quantity = cartItem[productId];
        const product = all_product_data.find(
          (p) => p.id === parseInt(productId)
        );

        if (product) {
          return {
            productId: product.id,
            name: product.name,
            price: product.new_price,
            quantity: quantity,
          };
        } else {
          // console.error(
          //   `Product with ID ${productId} not found in all_product_data`
          // );
          return null; // or handle this case as per your application logic
        }
      });

      const validPurchasedProducts = purchasedProducts.filter(
        (product) => product !== null
      );
      setPurchasedProduct(validPurchasedProducts);
    }
  }, [cartItem, all_product_data, purchaseComplete]);

  return (
    <div className="cartitems">
      <div className="cartitem-format-main">
        <p>Products</p>
        <p>Title</p>
        <p>Price</p>
        <p>Quantity</p>
        <p>Total</p>
        <p>Remove</p>
      </div>
      <hr />
      {all_product_data.map((product, index) => {
        if (cartItem[product.id] > 0) {
          return (
            <div key={index}>
              <div className="cartitem-format cartitem-format-main">
                <img
                  src={product.image}
                  alt=""
                  className="carticon-product-icon"
                />
                <p>{product.name}</p>
                <p>₹{product.new_price}</p>
                <button className="cartitem-quantity">
                  {cartItem[product.id]}
                </button>
                <p>₹{product.new_price * cartItem[product.id]}</p>
                <img
                  className="cartitem-remove-icon"
                  src={remove_icon}
                  onClick={() => {
                    removeFromCart(product.id);
                  }}
                  alt=""
                />
              </div>
              <hr />
            </div>
          );
        }
        return null;
      })}
      <div className="cartitem-down">
        <div className="cartitem-total">
          <h1>Cart Totals</h1>
          <div>
            <div className="cartitem-total-items">
              <p>Subtotal</p>
              <p>₹{getTotalCartAmount()}</p>
            </div>
            <hr />
            <div className="cartitem-total-items">
              <p>Shipping Fee</p>
              <p>Free</p>
            </div>
            <hr />
            <div className="cartitem-total-items">
              <h3>Total</h3>
              <h3>₹{getTotalCartAmount()}</h3>
            </div>
          </div>
          {!purchaseComplete && (
            <form onSubmit={handleFormSubmit}>
              <input
                className="user-details-form"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                className="user-details-form"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                className="user-details-form"
                type="tel"
                placeholder="Contact Number"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                required
              />
              <input
                className="user-details-form"
                type="text"
                placeholder="House No / Building Name"
                value={houseNo}
                onChange={(e) => setHouseNo(e.target.value)}
                required
              />
              <input
                className="user-details-form"
                type="text"
                placeholder="Road Name / Area / Colony"
                value={Area}
                onChange={(e) => setArea(e.target.value)}
                required
              />
              <input
                className="user-details-form"
                type="text"
                placeholder="Pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                required
              />
              <input
                className="user-details-form"
                type="text"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
              />
              <input
                className="user-details-form"
                type="text"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
              />
              <br />
              <button
                type="submit"
                onClick={() => {
                  setOrderType("online");
                }}
              >
                Proceed to Checkout (Online)
              </button>
              <br />
              <br />
              <button
                type="submit"
                onClick={() => {
                  setOrderType("offline");
                }}
              >
                Cash on delivery
              </button>
            </form>
          )}
          {purchaseComplete && (
        <div className="purchase-complete">
          <h2 >Thank you for your purchase!</h2>
          <h3 className="details-of-user">Payment ID: {paymentId}</h3>
          {paymentStatus && (
            <p className="details-of-user">Payment Status: {paymentStatus.status}</p>
          )}
          <h4>Purchased Products:</h4>
          {purchasedProduct.map((product, index) => (
            <div key={index}>
              <p className="details-of-user">
                {product.name} - {product.quantity} x ₹{product.price}
              </p>
            </div>
          ))}
          <br />
          <h2 >
            IF YOU HAVE ANY QUERY CONTACT HERE  <br /> <br />+91 96019 49059
          </h2>
        </div>
      )}
          {/* {purchaseComplete && (
            <div className="purchase-details">
              <h3>Customer Details:</h3>
              <p className="details-of-user">Name: {name}</p>
              <p className="details-of-user">Email: {email}</p>
              <p className="details-of-user">Contact: {contact}</p>

              {/* Show payment status only if available */}
              {/* {paymentStatus ? (
                <>
                  <h3>Payment Status:</h3>
                  <p className="details-of-user">Status: {paymentStatus.status}</p>
                  <p className="details-of-user">Method: {paymentStatus.method}</p>
                  <p className="details-of-user">Amount: ₹{paymentStatus.amount / 100}</p>
                  <p className="details-of-user">Currency: {paymentStatus.currency}</p>
                </>
              ) : (
                <p>Loading payment status...</p>
              )}

              <h2>Thank You for Purchasing a Product! Get Well Soon</h2>
              <h3>For Contact: +91 96019 49059</h3>
            </div> */}
          {/* )} } */}
        </div>
        <div className="cartitem-promocode">
          <p>If you have a promo code, enter it here</p>
          <div className="cartitem-promobox">
            <input type="text" placeholder="Promo code" />
            <button>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItems;

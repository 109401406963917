import React from "react";
import "./CSS/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="terms-container">
      <div className="terms-container-inner">
        <div>
          <p className="privacy-text">
            BFFstoreIN respects your privacy and is committed to protect via
            privacy policy, describes how and why we might collect, store, use,
            and/or share <b>(“process”)</b> your information when you use our
            services <b>(“Services”)</b>, such as when you can visit us at
            https://www.bffstorein.com or any website of ours that links to this
            privacy notice.
          </p>

          <p className="privacy-text">
            Reading this privacy notice will help you understand your privacy
            rights and choices. If you do not agree with our policies and
            practices, please do not use our Services. If you need any
            clarification, please contact us at bfftkub@gmail.comm
          </p>
        </div>
        <div>
          <p className="key-privacy-policy-list">
            Our Privacy Policy Key Notes:
          </p>
          <p className="key-privacy-policy-list">
            <b>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by reading
              below key points.
            </b>
          </p>

          <li className="list-policies">
            <b> 1. What personal information do we process?</b> When you visit,
            use, or navigate our Services, we may process personal information
            depending on how you interact with BFFstoreIN, the choices you make,
            and the products and features you use.
          </li>
          <li className="list-policies">
            <b> 2. Do we process any sensitive personal information?</b> We do
            not process sensitive personal information.
          </li>
          <li className="list-policies">
            <b> 3. Do we receive any information from third parties? </b> We do
            not receive any information from third parties.
          </li>
          <li className="list-policies">
            <b> 4. How do we process your information? </b> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so.
          </li>
          <li className="list-policies">
            <b>
              {" "}
              5. In what situations and with which parties do we share personal
              information?{" "}
            </b>{" "}
            We may share information in specific situations and with specific
            third parties.
          </li>
          <li className="list-policies">
            <b> 6. How do we keep your information safe? </b> We have
            organizational and technical processes and procedures in place to
            protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information.
          </li>
          <li className="list-policies">
            <b> 7. What are your rights? </b> Depending on where you are located
            geographically, the applicable privacy law may mean you have certain
            rights regarding your personal information.
          </li>
          <li className="list-policies">
            <b> 8. How do I exercise my rights? </b> The easiest way to exercise
            your rights is by contacting us. We will consider and act upon any
            request in accordance with applicable data protection laws.
          </li>
        </div>
        <div className="table-of-content">
        <p className="key-privacy-policy-list">
        Table Of Contents
          </p> <br />
        <div>
            
        <p className="key-privacy-policy-list">
        1. What Information Do We Collect?
          </p> 
          <ul>
            <li className="list-policies privacy-list"> <b>
            Personal information you disclose to us <br />
            In Short:</b>
            We collect personal information that you provide to us. We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
            </li>
            <li className="list-policies privacy-list"> <b>
            Personal Information Provided by You.</b> <br />
            The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following: Names, Phone numbers, Email addresses, Mailing addresses, Passwords, Billing addresses.
            </li>
            <li className="list-policies privacy-list"> <b>
            Sensitive Information.</b> We do not process sensitive information.
            </li>
            <li className="list-policies privacy-list"> <b>
            Payment Data.</b> We do not collect your payment details, except the mode of payment through which your purchases are made.
            </li>
            <li className="list-policies privacy-list"> All payment data is stored by Razorpay (<b><a className="link-razorpay" href="https://razorpay.com/privacy/">https://razorpay.com/privacy/</a></b>)
            </li>
            <li className="list-policies privacy-list"> All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </li>
            <li className="list-policies privacy-list"> <b>
            Information automatically collected<br />
            In Short:</b>
            Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
            </li>
            <li className="list-policies privacy-list">We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
            </li>   <li className="list-policies privacy-list"> The information we collect includes:<br/>
Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called “crash dumps”), and hardware settings).

            </li>
          </ul>
        </div>
        <br />

        </div>
        <div>
        <p className="key-privacy-policy-list">
        2. How Do We Process Your Information?
          </p> 
          <p className="privacy-text">
         <b> In Short: </b>We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent
          </p>
          <p className="privacy-text"> <b>

          We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
          </b>
          </p>
          <ul>
            <li className="list-policies privacy-list"> <b>
            To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.

            </li>
            <li className="list-policies privacy-list"> <b>
            To deliver and facilitate delivery of services to the user. </b> We may process your information to provide you with the requested service.
            </li>
            <li className="list-policies privacy-list"> <b>
            To respond to user inquiries/offer support to users.</b> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.

            </li>
            <li className="list-policies privacy-list"> <b>
            To send administrative information to you.</b> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
            </li>   
            <li className="list-policies privacy-list"> <b>
            To fulfil and manage your orders.</b> We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.
            </li>
            <li className="list-policies privacy-list"> <b>
            To request feedback.</b> We may process your information when necessary to request feedback and to contact you about your use of our Services.

            </li>   
            <li className="list-policies privacy-list"> <b>
            To send you marketing and promotional communications.</b> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time.
            </li>
            <li className="list-policies privacy-list"> <b>
            To post testimonials.</b> We post testimonials on our Services that may contain personal information.


            </li>   
            <li className="list-policies privacy-list"> <b>
            To protect our Services.</b> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
            </li>
            <li className="list-policies privacy-list"> <b>
            To evaluate and improve our Services, products, marketing, and your experience.</b> We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.

            </li>
            <li className="list-policies privacy-list"> <b>
            To identify usage trends.</b> We may process information about how you use our Services to better understand how they are being used so we can improve them.
            </li>
            <li className="list-policies privacy-list"> <b>
            To determine the effectiveness of our marketing and promotional campaigns.</b> We may process our information to better understand how to provide marketing and promotional campaigns that are most relevant to you.

            </li>
           
          </ul> 
            <br />
            </div>  
            <div>
        <p className="key-privacy-policy-list">
        3. When and With Whom Do We Share Your Personal Information?

          </p> 
          <p className="privacy-text">
         <b> In Short: </b>We may share information in specific situations described in this section and/or with the following third parties.
          </p>
          <p className="privacy-text"> <b>

          We may need to share your personal information in the following situations:

          </b>
          </p>
          <ul>
            <li className="list-policies privacy-list"> <b>
            Business Transfers. </b>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

            </li>
            <li className="list-policies privacy-list"> <b>
            Business Partners. </b>We may share your information with our business partners to offer you certain products, services, or promotions.
            </li>
           
          </ul> 
            <br />
            </div>  
            <div>
        <p className="key-privacy-policy-list">
        4. How Long Do We Keep Your Information?
          </p> 
          <p className="privacy-text">
         <b> In Short: </b>We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
          </p>
          <p className="privacy-text"> <b>

          We may need to share your personal information in the following situations:

          </b>
          </p>

            <p className="privacy-text">
            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
            </p>
            <p className="privacy-text">
            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.

            </p>
            <br />

          </div>
          <div>
        <p className="key-privacy-policy-list">
        5. How Do We Keep Your Information Safe?
          </p>
          <p className="privacy-text">
         <b> In Short: </b>We aim to protect your personal information through a system of organizational and technical security measures.

          </p><br/>

            <p className="privacy-text">
            We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
            </p>
            <br />

          </div>
          <div>
        <p className="key-privacy-policy-list">
        6. What Are Your Privacy Rights?
                  </p> 
          <p className="privacy-text">
         <b> In Short: </b>You may review, change, or terminate your account at any time.

          </p>

            <p className="privacy-text">
            <b>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided below.
            </p>
            <p className="privacy-text">
                
However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </p>
            <p className="privacy-text">
            <b>Opting out of marketing and promotional communications:</b> You can unsubscribe from our marketing and promotional communications at any time by or by contacting us using the details provided on the website. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </p>
            <p className="privacy-text"><b>Account Information
            </b></p>
            <p className="privacy-text">If you would at any time like to review or change the information in your account, you can: Log in to your account settings and update your user account.
            </p>
            <p className="privacy-text">However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
            </p>
            <p className="privacy-text">If you have questions or comments about your privacy rights, you may email us at bfftkub@gmail.com.
            </p>
            <br />
          </div>
          <div>
        <p className="key-privacy-policy-list">
        7. Controls For Do-Not-Track Features
                  </p> 
          <p className="privacy-text">
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
          </p>
            <br />
          </div>
          <div>
        <p className="key-privacy-policy-list">
        8. Do We Make Updates To This Notice?
                  </p> 
          <p className="privacy-text">
         <b> In Short: </b>Yes, we will update this notice as necessary to stay compliant with relevant laws.
          </p>
            <p className="privact-text">
                
We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.

            </p>
          <br/>
          </div>
          <div>
        <p className="key-privacy-policy-list">
        9. How Can You Contact Us About This Notice?
                  </p> 
          <p className="privacy-text">
          If you have questions or comments about this notice, you may email us at bfftkub@gmail.com or by post to:
          </p>
          <p className="privacy-text">
          BFFstoreIN
          </p>
          <p className="privacy-text">B103, Maruti Trade Center</p>
          <p className="privacy-text">Velanja Surat Gujarat – 394150</p>
            <br />
          </div>
          <div>
        <p className="key-privacy-policy-list">
        10. How Can You Review, Update, Or Delete The Data We Collect From You?
                  </p> 
         
          <p className="privacy-text">
          Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances.
          </p>
            <br />
          </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React from 'react'

const ShippingDelivery = () => {
  return (
    <div className="terms-container">
        <div className='terms-container-inner'>
            <p className='privacy-text'>
                <b>Shipping & Processing Timeline
                </b>
            </p>
            <p className='privacy-text'>All Orders are processed within 2-3 Business Days.
            </p>
            <p className='privacy-text'>Orders are not shipped or delivered on weekends or holidays.
            </p>
            <p className='privacy-text'>If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please allow additional days in transit for delivery. Same will be communicated for your order. We will contact you via email or telephone.</p> <br />
            <p className='privacy-text'> <b>
            Shipping Rates & Delivery Estimates
            </b></p>
            <p className='privacy-text'>Shipping charges for your orders will be calculated and displayed at checkout.
            </p>
            <p className='privacy-text'>Shipping method: Standard
            </p>
            <p className='privacy-text'>Shipment cost: Free
            </p>
            <p className='privacy-text'>Estimated delivery time: 3-5 business days
            </p>
            <p className='privacy-text'> <b>
            Shipment Confirmation & Order Tracking
            </b></p>
            <p className='privacy-text'>You will receive a Shipment Confirmation Email once your order has shipped containing your tracking number(s). The tracking number will be active within 24 hours.</p>
        </div>
    </div>
  )
}

export default ShippingDelivery
